import { Box, Grid, styled } from "@mui/material";

export const OverviewBoxStyled = styled((props) => <Box {...props} />)(() => ({
  padding: "24px",
  border: "1px solid rgba(255, 255, 255, .15)",
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, .3)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "180px",
}));

export const OverviewGridContainerStyled = styled((props) => (
  <Grid container spacing={2} sx={{ mb: { xs: 5, md: 0 } }} {...props} />
))(() => ({}));

export const OverviewButtonBoxStyled = styled((props) => (
  <Box textAlign="right" {...props} />
))(() => ({}));
