import { useEffect, useState } from "react";

export const useVisualError = () => {
  const [isErrorVisual, setErrorVisual] = useState(false);

  useEffect(() => {
    const errorTimeOut = setTimeout(() => {
      setErrorVisual(false);
    }, 6000);

    return () => {
      clearTimeout(errorTimeOut);
    };
  }, [isErrorVisual]);

  return [isErrorVisual, setErrorVisual];
};
