import { FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OISpecialInstructionsInput = () => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const currentOption = useSelector((state) => state.orderIntake.currentOption);
  const instructions = getValues()?.driverInstructions;

  const validateInstructions = (input) =>
    !Boolean(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(input));

  const [switchState, setSwitchState] = useState(false);
  const handleSwitch = () => setSwitchState((prev) => !prev);

  useEffect(() => {
    instructions ? setSwitchState(true) : setSwitchState(false);
  }, [instructions]);

  return (
    <>
      <Controller
        name="driverInstructions"
        control={control}
        defaultValue={currentOption.driverInstructions}
        rules={{
          validate: validateInstructions,
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <FormControlLabel
              label={t("orderIntake.addDriverInstruction")}
              labelPlacement="start"
              control={
                <Switch
                  size="small"
                  checked={switchState}
                  onChange={handleSwitch}
                />
              }
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: 0,
              }}
            />
            <TextField
              fullWidth
              multiline
              error={error}
              rows={4}
              value={field.value}
              onChange={(e) => field.onChange(e)}
              id="oi-delivery-form-instructions"
              label={t("orderIntake.driverInstructions")}
              variant="filled"
              helperText={`${field.value?.length || 0} / 200`}
              inputProps={{ maxLength: 200 }}
              sx={{
                display: switchState ? "inline-flex" : "none",
              }}
            />
            {error && (
              <Typography
                variant="caption"
                sx={{ color: ({ palette }) => palette.error.main }}
              >
                {t("orderIntake.driverInstructionsInvalidCharacters")}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
};

export default OISpecialInstructionsInput;
