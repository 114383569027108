import { Grid, Typography } from "@mui/material";
import CustomerNumberFilter from "Components/UI/Filters/CustomerNumberFilter/CustomerNumberFilter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PageHeading } from "../UI/Typography/SharedTypography";
import { OverviewGridContainerStyled } from "./Shared";

const OverviewGrid = () => {
  const { t } = useTranslation();

  const currentCustomer = useSelector(
    (state) => state.contentTable.currentCustomer
  );

  return (
    <>
      <PageHeading>{t("overview.title")}</PageHeading>
      <OverviewGridContainerStyled>
        <Grid item md={6} xs={12} sx={{ marginTop: 2 }}>
          <CustomerNumberFilter isOverview />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="body"
            sx={{
              display: "block",
              fontWeight: 500,
              marginBottom: 2,
            }}
          >
            {t("overview.accountNumber")} {currentCustomer.customerNumber}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {currentCustomer.customerName}
          </Typography>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default OverviewGrid;
