import { createSlice } from "@reduxjs/toolkit";
import { sortByMaterial } from "helpers/helpers";

const initialState = {
  isTabsFormOpen: true,
  isSummaryOpen: false,
  isConfirmation: false,
  isConfirmationError: false,

  deliveryOptions: [],
  currentOption: {},
  currentMaterial: {},
  deliveryDates: [],
  summary: {},

  currentOrderId: 0,
  tableRows: [],
};

const orderIntakeSlice = createSlice({
  name: "orderIntake",
  initialState,
  reducers: {
    setIsTabsFormOpen: (state, action) => {
      state.isTabsFormOpen = action.payload;
    },
    setSummaryOpen: (state, action) => {
      state.isSummaryOpen = action.payload;
    },
    setIsConfirmation: (state, action) => {
      state.isConfirmation = action.payload;
    },
    setIsConfirmationError: (state, action) => {
      state.isConfirmationError = action.payload;
    },

    setDeliveryOptions: (state, action) => {
      state.deliveryOptions = action.payload;
    },
    setCurrentOption: (state, action) => {
      state.tableRows = [];
      state.currentOption = action.payload;
    },
    setCurrentMaterial: (state, action) => {
      state.currentMaterial = action.payload;
    },
    setDeliveryDates: (state, action) => {
      state.deliveryDates = action.payload;
    },
    setSummary: (state) => {
      state.summary = state.tableRows.reduce(sortByMaterial, {});
    },

    setCurrentOrderId: (state, action) => {
      state.currentOrderId = action.payload;
    },
    setTableRow: (state, action) => {
      const foundIndex = state.tableRows.findIndex(
        (order) => order.orderId === state.currentOrderId
      );

      const usedIndex = foundIndex === -1 ? 0 : foundIndex;

      state.tableRows[usedIndex] = {
        ...action.payload,
        orderId: state.currentOrderId,
      };
    },
    removeTableRow: (state, action) => {
      state.isTabsFormOpen = false;
      state.tableRows = state.tableRows.filter(
        (order) => order.orderId !== action.payload
      );
    },
    addAnotherDelivery: (state) => {
      const latestOrder = state.tableRows.at(-1);
      const latestOrderId = latestOrder.orderId;

      state.tableRows = [
        ...state.tableRows,
        { ...latestOrder, orderId: latestOrderId + 1 },
      ];
      state.currentOrderId = latestOrderId + 1;
      state.isTabsFormOpen = true;
    },
  },
});

export const { actions } = orderIntakeSlice;
export default orderIntakeSlice.reducer;
