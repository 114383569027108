import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as orderListActions } from "reduxStore/slices/contentTable";
import { actions as invoicesActions } from "reduxStore/slices/invoices.js";
import { actions as orderIntakeActions } from "reduxStore/slices/orderIntake.js";
import { actions as snackMessagesActions } from "reduxStore/slices/snackMessages.js";

const rootActions = {
  ...orderListActions,
  ...orderIntakeActions,
  ...invoicesActions,
  ...snackMessagesActions,
};

export function useActions() {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
}
