import { Box, Divider, Typography } from "@mui/material";
import { email } from "assets/img/images";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ConfirmationText, PinCodeHeadings } from "../UI/Typography/DeliveryOI";
import { CaptionHeading } from "../UI/Typography/SharedTypography";

const OIPinCodeDetails = () => {
  const { t } = useTranslation();

  const numberOfOrders = useSelector(
    (state) => state.orderIntake.tableRows.length
  );
  return (
    <Box sx={{ padding: 1, marginTop: 2, marginBottom: 4 }}>
      <PinCodeHeadings>{t("orderIntake.pinCodeSent")}</PinCodeHeadings>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <ConfirmationText>
          {t("orderIntake.confirmationScreen.qrCodeSentText", {
            count: numberOfOrders,
          })}
        </ConfirmationText>
        <Box sx={{ position: "relative" }}>
          <img
            src={email}
            alt="Check your email"
            style={{ maxWidth: "50px", textAlign: "center" }}
          />
          <Box
            sx={{
              top: "4px",
              color: "white",
              right: "6px",
              width: "18px",
              height: "18px",
              display: "flex",
              padding: "0px",
              position: "absolute",
              fontSize: "12px",
              alignItems: "center",
              fontWeight: "bold",
              borderRadius: "10px",
              justifyContent: "center",
              backgroundColor: "orange",
            }}
          >
            {numberOfOrders}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "15px",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            color: "white",
            width: "35px",
            height: "35px",
            display: "flex",
            alignItems: "center",
            borderRadius: "30px",
            justifyContent: "center",
            backgroundColor: "#11507B",
          }}
        >
          <Typography>HC</Typography>
        </Box>
        <Box>
          <CaptionHeading>
            {t("orderIntake.confirmationScreen.subject")}
          </CaptionHeading>
          <PinCodeHeadings>
            {t("orderIntake.confirmationScreen.pinCodeForOrder")}
          </PinCodeHeadings>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CaptionHeading>
              {t("orderIntake.confirmationScreen.from")}
            </CaptionHeading>
            <Typography sx={{ fontSize: "14px" }}>
              noreply@hconnect.digital
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OIPinCodeDetails;
