import { Button, Typography } from "@mui/material";
import { useState } from "react";
import Countdown from "react-countdown";
import { Trans, useTranslation } from "react-i18next";
import { useLoginMutation } from "reduxStore/api/authApiSlice";

const QuickCodeCountdown = ({ submitLoginForCode }) => {
  const { t } = useTranslation();
  const [, { isLoading: isCheckingLogin }] = useLoginMutation({
    fixedCacheKey: "shared-login-check",
  });

  const initialCountdown = 60 * 1000;
  const [countdown, setCountdown] = useState(Date.now() + initialCountdown);
  const [k, setK] = useState(false);

  const handleRestartTimer = () => {
    submitLoginForCode();
    setK((i) => !i);
    setCountdown(Date.now() + initialCountdown);
  };

  const renderer = ({ completed, total }) => {
    let countdown;
    if (completed) {
      return (
        <Button
          onClick={handleRestartTimer}
          disabled={isCheckingLogin}
          sx={{ marginY: 2 }}
        >
          {t("landingPage.getCode")}
        </Button>
      );
    } else if (total) countdown = total / 1000;

    return (
      <Typography
        variant="body"
        sx={{ marginY: 2, padding: "6px 8px", textAlign: "center" }}
      >
        <Trans
          i18nKey="landingPage.countdown"
          values={{ seconds: countdown }}
          components={{
            1: <span style={{ width: "21px", display: "inline-block" }} />,
          }}
        />
      </Typography>
    );
  };

  return (
    <Countdown
      key={k}
      zeroPadTime={0}
      daysInHours
      renderer={renderer}
      date={countdown}
    />
  );
};

export default QuickCodeCountdown;
