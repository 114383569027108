import { Box, Button, Typography } from "@mui/material";
import { setItemToLS } from "helpers/localstoragehelper";
import { useActions } from "hooks/useActions";
import { useVisualError } from "hooks/useError";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import {
  useLoginByCodeMutation,
  useLoginMutation,
} from "reduxStore/api/authApiSlice";
import QuickCodeCountdown from "./QuickCodeCountdown";
import "./VerificationInput.css";

const ByQuickCode = ({ setPasswordLogin, username }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isErrorVisual, setErrorVisual] = useVisualError();
  const { setSnackMessage } = useActions();

  const [loginByCode, { isLoading, isError: isCodeError }] =
    useLoginByCodeMutation({});

  const [checkLogin, { data }] = useLoginMutation({
    fixedCacheKey: "shared-login-check",
  });

  const [code, setCode] = useState("");
  const [contact, setContact] = useState(data?.login);

  const handleCodeInput = (value) => setCode(value);
  const submitLoginForCode = async () => {
    await checkLogin({
      login: username,
    })
      .unwrap()
      .then((data) => {
        setContact(data?.login);
        setSnackMessage("Code sent");
      })
      .catch((rejected) => setSnackMessage("Error sending code: " + rejected));
  };

  const submitCode = async (code) => {
    try {
      const userData = await loginByCode({
        code: code,
        redirect: data.redirect,
      }).unwrap();
      setItemToLS("token", userData.token);
      setItemToLS("userId", userData.userId);
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      setSnackMessage(`Error ${error?.status}: ${error?.data?.error}`);
      setErrorVisual(true);
    }
  };

  useEffect(() => {
    setErrorVisual(isCodeError);
  }, [setErrorVisual, isCodeError]);

  return (
    <>
      <Typography variant="h6" marginBottom={3}>
        {t("landingPage.insertCode")}
      </Typography>
      <Typography variant="body" marginBottom={3}>
        {t("landingPage.codeSent", { login: contact })}
      </Typography>
      <form style={{ display: "contents" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{username}</Typography>
          <Button
            onClick={setPasswordLogin}
            sx={{ p: 0, textTransform: "capitalize" }}
          >
            {t("landingPage.change")}
          </Button>
        </Box>
        <Box className="custom-styles" sx={{ my: 2, textAlign: "center" }}>
          <VerificationInput
            value={code}
            onChange={handleCodeInput}
            onComplete={submitCode}
            placeholder=""
            validChars="0-9"
            length={6}
            classNames={{
              container: "code-input-container",
              character: `code-input-character ${isErrorVisual ? "error" : ""}`,
            }}
          />
        </Box>
        <Button
          variant="contained"
          disabled={isLoading}
          onClick={() => submitCode(code)}
        >
          {t("landingPage.loginButton")}
        </Button>
        <QuickCodeCountdown submitLoginForCode={submitLoginForCode} />
        <Link onClick={setPasswordLogin}>{t("landingPage.usePassword")}</Link>
      </form>
    </>
  );
};

export default ByQuickCode;
