import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import contentTableReducer from "./slices/contentTable";
import invoicesReducer from "./slices/invoices";
import orderIntakeReducer from "./slices/orderIntake";
import snackMessagesReducer from "./slices/snackMessages";

export const store = configureStore({
  reducer: {
    contentTable: contentTableReducer,
    orderIntake: orderIntakeReducer,
    invoices: invoicesReducer,
    snackMessages: snackMessagesReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});
