import { Box, styled } from "@mui/material";
import { copyright } from "constants/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CaptionHeading } from "../UI/Typography/SharedTypography";

const StyledLoginFooterLink = styled((props) => <Link {...props} />)(
  ({ theme }) => ({
    // width: "min-content",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: theme.palette.text.secondary,
    },
  })
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        boxSizing: "border-box",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        columnGap: 4,
        width: "100%",
        padding: 2,
        borderTop: "1px solid #628390",
        "&>*": { color: "white" },
        textAlign: "center",
      }}
    >
      <CaptionHeading>{copyright}</CaptionHeading>
      <Box sx={{ flexDirection: { xs: "column", sm: "row" } }}>
        <Box
          sx={{
            display: "flex",
            columnGap: 1,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <StyledLoginFooterLink to="/tos">
            <CaptionHeading>{t("accountMenu.legal.termsofuse")}</CaptionHeading>
          </StyledLoginFooterLink>
          <CaptionHeading sx={{ display: { xs: "none", sm: "block" } }}>
            |
          </CaptionHeading>
          <StyledLoginFooterLink to="/privacy">
            <CaptionHeading>{t("accountMenu.legal.privacy")}</CaptionHeading>
          </StyledLoginFooterLink>
        </Box>
        <StyledLoginFooterLink to="/oss">
          <CaptionHeading>
            {t("accountMenu.legal.openSourceSoftwareAttribution")}
          </CaptionHeading>
        </StyledLoginFooterLink>
      </Box>
    </Box>
  );
};

export default Footer;
