import { Box, Toolbar, Typography } from "@mui/material";
import OIDesktopLayout from "Components/OrderIntake/OIDesktopLayout";
import OIMobileLayout from "Components/OrderIntake/OIMobileLayout";
import BackButton from "Components/UI/BackButton/BackButton.jsx";
import Loader from "Components/UI/Loader/Loader";
import { IsCollectProvider } from "context/IsCollectProvider";
import { getCutOffDates, resetFormObject } from "helpers/helpers";
import { useActions } from "hooks/useActions.jsx";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled.jsx";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetDeliveryOptionsQuery } from "reduxStore/api/deliveryApiSlice.js";

const OrderIntake = ({ isCollect = false }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const hookFormMethods = useForm({ mode: "onChange" });
  const { reset, handleSubmit, getValues } = hookFormMethods;
  console.log("OrderIntake render");
  console.log(getValues());

  const {
    isFetching: isFetchingDeliveries,
    isSuccess: isSuccessDeliveries,
    data,
  } = useGetDeliveryOptionsQuery(isCollect ? "collect" : "deliver", {
    refetchOnMountOrArgChange: true,
  });

  const currentOption = useSelector((state) => state.orderIntake.currentOption);
  console.log("currentOption", currentOption);

  const { setIsConfirmation, setIsTabsFormOpen, setSummary, setTableRow } =
    useActions();

  const submitHookForms = (data) => {
    setTableRow({
      ...data,
      orderId: data?.orderId,
    });
    setSummary();
  };

  const memoizedSubmitHookForms = useCallback(submitHookForms, [
    setSummary,
    setTableRow,
  ]);

  useEffect(() => {
    const defaultFormValues = isSuccessDeliveries
      ? resetFormObject({
          ...currentOption,
          date: getCutOffDates(currentOption)[0],
        })
      : {};

    reset(defaultFormValues);
    handleSubmit(memoizedSubmitHookForms)();
    setIsConfirmation(false);
    setIsTabsFormOpen(true);
  }, [
    handleSubmit,
    memoizedSubmitHookForms,
    reset,
    setIsConfirmation,
    setIsTabsFormOpen,
    currentOption,
    isSuccessDeliveries,
  ]);

  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderIntake");

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;

  if (isFetchingDeliveries) return <Loader />;

  if (!data?.length)
    return <Typography sx={{ color: "white" }}>No options!</Typography>;

  return (
    <IsCollectProvider isCollect={isCollect}>
      <Toolbar>
        <BackButton>
          {isCollect
            ? t("overview.orderIntakeCollectWidgetTitle")
            : t("overview.scheduleNewDelivery")}
        </BackButton>
      </Toolbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: { md: 4 },
          pt: { md: 0 },
        }}
      >
        <FormProvider {...hookFormMethods}>
          {isDesktop ? <OIDesktopLayout /> : <OIMobileLayout />}
        </FormProvider>
      </Box>
    </IsCollectProvider>
  );
};

export default OrderIntake;
