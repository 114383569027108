import { useTranslation } from "react-i18next";

const FormatCurrency = ({
  currency,
  currencySign,
  useGrouping,
  minimumIntegerDigits,
  minimumFractionDigits,
  maximumFractionDigits,
  minimumSignificantDigits,
  maximumSignificantDigits,
  formatStyle = "currency",
  locale,
  value,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, {
    style: formatStyle,
    currency,
    currencySign,
    useGrouping,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
  });

  return (
    <span data-number={value?.toString()} {...rest}>
      {value === undefined || Number.isNaN(value)
        ? ""
        : numberFormatter.format(value).replace(/\u00A0/g, " ")}
    </span>
  );
};

export default FormatCurrency;
