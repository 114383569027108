import { Box, Paper } from "@mui/material";
import { HCLogo } from "assets/img/images";
import ByPassword from "Components/Login/ByPassword";
import ByQuickCode from "Components/Login/ByQuickCode";
import CookieModal from "Components/Login/CookieModal";
import Footer from "Components/Login/Footer";
import { useState } from "react";

const Login = () => {
  const [loginType, setLoginType] = useState("password");

  const setCodeLogin = () => setLoginType("quickCode");
  const setPasswordLogin = () => setLoginType("password");

  const [username, setUsername] = useState("");
  const handleUsername = ({ target: { value } }) => setUsername(value);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Paper
          component="main"
          sx={{
            padding: { xs: 3, sm: 6 },
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            minHeight: "500px",
            maxWidth: { xs: "600px", sm: "450px" },
            width: "100%",
            margin: "auto 0",
            flex: { xs: 1, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            <Box sx={{ width: "200px" }}>
              <img src={HCLogo} alt="branding logo" />
            </Box>
          </Box>
          {loginType === "password" && (
            <ByPassword
              setCodeLogin={setCodeLogin}
              username={username}
              handleUsername={handleUsername}
            />
          )}
          {loginType === "quickCode" && (
            <ByQuickCode
              setPasswordLogin={setPasswordLogin}
              username={username}
            />
          )}
        </Paper>
        <Footer />
      </Box>
      <CookieModal />
    </>
  );
};

export default Login;
