import { Button, Grid, Paper } from "@mui/material";
import OIAdditionalInfo from "Components/OrderIntake/OIAdditionalInfo.jsx";
import OITabsForm from "Components/OrderIntake/OITabsForm.jsx";
import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OIConfirmation from "./OIConfirmation";
import OIOverview from "./OIOverview";

const OIMobileLayout = ({ isCollect }) => {
  const { t } = useTranslation();
  const { setIsTabsFormOpen, setSummaryOpen } = useActions();

  const isConfirmation = useSelector(
    (state) => state.orderIntake.isConfirmation
  );

  const isTabsFormOpen = useSelector(
    (state) => state.orderIntake.isTabsFormOpen
  );

  const handleShowSummary = () => {
    setIsTabsFormOpen(true);
    setSummaryOpen(true);
  };

  useEffect(() => {
    setIsTabsFormOpen(false);
  }, [setIsTabsFormOpen]);

  return (
    <Paper sx={{ width: "100%" }}>
      {isConfirmation ? (
        <>
          <Grid item xs={12} md={8} sx={{ mb: { xs: 4, md: 0 }, p: 1 }}>
            <OIConfirmation isCollect={isCollect} />
          </Grid>
          <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
            <Paper sx={{ p: 4 }}>
              <OIAdditionalInfo isCollect={isCollect} />
            </Paper>
          </Grid>
        </>
      ) : (
        <>
          {!isTabsFormOpen && (
            <Grid item xs={12} md={8} sx={{ mb: { xs: 4, md: 0 }, p: 2 }}>
              <OIOverview isCollect={isCollect} />
              <Button
                variant="contained"
                fullWidth
                sx={{ marginTop: 4 }}
                onClick={handleShowSummary}
              >
                {t("orderIntake.placeOrder")}
              </Button>
            </Grid>
          )}
          {isTabsFormOpen && (
            <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
              <Paper sx={{ p: 4 }}>
                <OITabsForm isCollect={isCollect} />
              </Paper>
            </Grid>
          )}
        </>
      )}
    </Paper>
  );
};

export default OIMobileLayout;
