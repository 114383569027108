import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StatusIcon from "../UI/StatusIcon/StatusIcon";

const FullScreenDeliveriesStatus = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate({
      pathname: "/invoices/details/",
      search: `${data.links[1].query}&customerId=${data.customerId}`,
    });
  };

  switch (data.deliveryStatus) {
    case "invoiced":
      return (
        <Button onClick={handleNavigation}>
          {t("order.expand.label.invoiced")}
        </Button>
      );
    case "intransit":
      return (
        <Box
          sx={{
            paddingTop: "2px",
            display: "flex",
            columnGap: "5px",
            textDecoration: "underline",
          }}
        >
          <StatusIcon small status="intransit" />
          {t("order.expand.label.intransit")}
        </Box>
      );
    default:
      return null;
  }
};

export default FullScreenDeliveriesStatus;
