import { Box } from "@mui/material";
import { timeOptions } from "constants/constants";
import { findCurrentIndex } from "helpers/helpers";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TimeInput from "../../UI/TimeInput/TimeInput";

const wholeDayRange = {
  defaultDeliveryWindow: {
    defaultEarliestLoadTime: "00:00",
    defaultLatestLoadTime: "23:59",
  },
};

const TimePickerOwn = ({ wholeDay = false }) => {
  const { t } = useTranslation();
  const { getValues, watch, setValue } = useFormContext();

  const formTimeValues = getValues()?.defaultDeliveryWindow;

  const currentOption = useSelector((state) => state.orderIntake.currentOption);

  const { defaultDeliveryWindow } = currentOption;

  const defaultEarliestLoadTime =
    formTimeValues?.defaultEarliestLoadTime ||
    defaultDeliveryWindow?.defaultEarliestLoadTime;
  const defaultLatestLoadTime =
    formTimeValues?.defaultLatestLoadTime ||
    defaultDeliveryWindow?.defaultLatestLoadTime;

  const watchEarliest = watch(
    "defaultDeliveryWindow.defaultEarliestLoadTime",
    defaultEarliestLoadTime
  );
  const watchLatest = watch(
    "defaultDeliveryWindow.defaultLatestLoadTime",
    defaultLatestLoadTime
  );

  const [, setSavedTime] = useState({
    defaultEarliestLoadTime,
    defaultLatestLoadTime,
  });

  const [earliestIndex, setEarliestIndex] = useState(
    findCurrentIndex(watchEarliest)
  );

  const [latestIndex, setLatestIndex] = useState(findCurrentIndex(watchLatest));

  useEffect(() => {
    // ! refactor this, setSavedTime should return state as well as avoid calling other functions
    const formTimeValues = getValues()?.defaultDeliveryWindow;
    if (wholeDay) {
      setSavedTime(formTimeValues);
      setValue("defaultDeliveryWindow", wholeDayRange.defaultDeliveryWindow);
    } else {
      setSavedTime((prev) => {
        setValue("defaultDeliveryWindow", prev);
      });
    }
  }, [setValue, wholeDay, getValues]);

  useEffect(() => {
    const timeOptionsLastIndex = timeOptions.length - 1;
    if (earliestIndex === latestIndex) {
      if (latestIndex === timeOptionsLastIndex) {
        setEarliestIndex(timeOptionsLastIndex - 1);
      } else {
        setLatestIndex((prev) => prev + 1);
      }
    } else if (earliestIndex > latestIndex) {
      setLatestIndex(earliestIndex + 1);
    } else if (latestIndex === 0) {
      setLatestIndex(earliestIndex + 1);
    }
  }, [earliestIndex, latestIndex]);

  useEffect(() => {
    const subscription = watch(({ defaultDeliveryWindow }, { type }) => {
      if (type) return;
      setEarliestIndex(
        findCurrentIndex(defaultDeliveryWindow.defaultEarliestLoadTime)
      );
      setLatestIndex(
        findCurrentIndex(defaultDeliveryWindow.defaultLatestLoadTime)
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <TimeInput
        label={t("orderIntake.earliest")}
        id="startTime"
        time={watchEarliest}
        index={earliestIndex}
        setIndex={setEarliestIndex}
        options={timeOptions.slice(0, -1)}
        registerName="defaultEarliestLoadTime"
        isDisabled={wholeDay}
      />
      <Box marginBottom={2} />
      <TimeInput
        label={t("orderIntake.latest")}
        id="endTime"
        time={watchLatest}
        index={latestIndex}
        setIndex={setLatestIndex}
        options={timeOptions.slice(earliestIndex + 1)}
        registerName="defaultLatestLoadTime"
        isDisabled={wholeDay}
      />
    </>
  );
};

export default TimePickerOwn;
