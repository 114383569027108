import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useActions } from "hooks/useActions";
import useIsCollect from "hooks/useIsCollect";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OICountdown from "./OICountdown";

const StyledTypography = styled((props) => <Typography {...props} />)(() => ({
  fontSize: "14px",
}));

const OITableRow = ({ order, index }) => {
  const { t } = useTranslation();
  const { reset } = useFormContext();
  const isCollect = useIsCollect();

  const { removeTableRow, setIsTabsFormOpen, setSummary, setCurrentOrderId } =
    useActions();
  const tableRows = useSelector((state) => state.orderIntake.tableRows);

  const currentOrderId = useSelector(
    (state) => state.orderIntake.currentOrderId
  );

  const [isTimeOut, setIsTimeOut] = useState(false);

  const backgroundColor = () => {
    if (isTimeOut) return { color: "#FF6666", hover: "#FF3333" };
    if (currentOrderId === order.orderId)
      return { color: "#EBF3FC", hover: "#D4EEFF" };

    return { color: "white", hover: "#D4EEFF" };
  };

  const handleRemoveTableRow = (e, id) => {
    e.stopPropagation();
    removeTableRow(id);
    setSummary();
  };

  const handleRowClick = (order) => {
    const clickedRow = tableRows.find(
      (tableRow) => tableRow.orderId === order.orderId
    );

    setCurrentOrderId(clickedRow.orderId);
    reset({ ...order, isTimeOut, edit: true });
    setIsTabsFormOpen(true);
  };

  useEffect(() => {
    isTimeOut && reset({ ...order, isTimeOut });
  }, [isTimeOut, order, reset]);

  return (
    <TableRow
      hover
      onClick={() => handleRowClick(order)}
      sx={{
        backgroundColor: `${backgroundColor().color}`,
        "&.MuiTableRow-hover:hover": {
          backgroundColor: backgroundColor().hover,
        },
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell>
        <StyledTypography>{index + 1}</StyledTypography>
      </TableCell>
      <TableCell>
        <Box>
          <StyledTypography>
            <FormatDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              timeStart={order?.date}
            />
          </StyledTypography>
          <StyledTypography
            sx={{ whiteSpace: "nowrap" }}
          >{`${order.defaultDeliveryWindow.defaultEarliestLoadTime} ${order.defaultDeliveryWindow.defaultLatestLoadTime}`}</StyledTypography>
        </Box>
        <OICountdown date={order?.date} setIsTimeOut={setIsTimeOut} />
      </TableCell>
      <TableCell>
        <Box>
          <StyledTypography>
            {isTimeOut
              ? t("orderIntake.timeout")
              : `${order.materials?.materialDescription}`}
          </StyledTypography>
        </Box>
      </TableCell>
      <TableCell>{order.truckCapacity.capacity}</TableCell>
      {isCollect && (
        <TableCell>
          <Typography
            sx={{ fontSize: "12px" }}
          >{`${order.haulerInfo.driverName} ${order.haulerInfo.driverPhoneNumber}`}</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {`${order.haulerInfo.truckLicensePlate}`}
            {order.haulerInfo.trailerLicensePlate &&
              `|${order.haulerInfo.trailerLicensePlate}`}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        {order.driverInstructions && order.driverInstructions}
      </TableCell>
      <TableCell>{order.customerReference}</TableCell>
      <TableCell sx={{ px: 1 }}>
        <IconButton
          onClick={(e) => handleRemoveTableRow(e, order.orderId)}
          sx={{
            visibility: `${order.orderId === 0 && "hidden"}`,
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default OITableRow;
