import { Button } from "@mui/material";
import InputHelperCount from "Components/UI/InputHelperCount/InputHelperCount";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DriverPhoneInput from "./DriverPhoneInput";

const HaulerTab = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const clearHaulerInfo = () => {
    setValue("haulerInfo", {
      driverName: "",
      driverPhoneNumber: "",
      truckLicensePlate: "",
      trailerLicensePlate: "",
    });
  };

  return (
    <>
      <InputHelperCount
        label={t("order.orderDetails.driverName")}
        id="driverName"
      />
      <DriverPhoneInput />
      <InputHelperCount
        label={t("orderIntake.haulerInfo.truckLicensePlate")}
        id="truckLicensePlate"
      />
      <InputHelperCount
        label={t("orderIntake.haulerInfo.trailerLicensePlate")}
        id="trailerLicensePlate"
      />
      <Button sx={{ display: "block", ml: "auto" }} onClick={clearHaulerInfo}>
        {t("orderIntake.haulerInfo.clear")}
      </Button>
    </>
  );
};

export default HaulerTab;
