import { FormControl, FormHelperText, Input } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const InputHelperCount = ({ label = "", id = "unknown id" }) => {
  const { register } = useFormContext();
  const inputFormName = `haulerInfo.${id}`;
  const helperMaxLength = 35;
  const [inputLength, setInputLength] = useState(0);

  return (
    <FormControl
      fullWidth
      sx={{
        marginBottom: 2,
        backgroundColor: "#F7F7F7",
        border: "1px solid #E7E7E7",
        borderRadius: "4px",
        transition: "none",
        paddingX: 2,
        paddingY: 1,
        boxSizing: "border-box",
        "&:hover": {
          background: "#E7E7E7",
        },
      }}
    >
      <Input
        {...register(inputFormName)}
        placeholder={label}
        onInput={({ target }) => setInputLength(target.value.length)}
        inputProps={{
          maxLength: helperMaxLength,
        }}
      />
      <FormHelperText sx={{ m: 0 }}>
        {`${inputLength}/${helperMaxLength}`}
      </FormHelperText>
    </FormControl>
  );
};

export default InputHelperCount;
