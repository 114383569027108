import HubSnackbar from "Components/UI/HubSnackbar/HubSnackbar";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "reduxStore/store";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <Provider store={store}>
    <HubSnackbar />
    <App />
  </Provider>
);
