import { Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "reduxStore/api/authApiSlice";
import AccountsMenu from "./AccountsMenu";
import CopyrightMenu from "./CopyrightMenu";
import LanguageMenu from "./LanguageMenu";
import LegalMenu from "./LegalMenu";
import LogoutMenu from "./LogoutMenu";
import ManageMenu from "./ManageMenu";

const UserMenu = () => {
  const navigate = useNavigate();
  const userData = useUser();

  const [logout] = useLogoutMutation();

  const handleLogOut = () => {
    handleCloseUserMenu();
    logout();
    navigate("/login");
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleOpenUserMenu = (e) => setAnchorElUser(e.currentTarget);

  return (
    <Box sx={{ flexGrow: 0, paddingRight: { xs: 0, lg: "10px" } }}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar alt={userData?.name} src="/static/images/avatar/2.jpg" />
      </IconButton>
      <Menu
        id="appbar-usermenu"
        disableScrollLock
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: "45px" }}
      >
        <MenuItem
          sx={{
            padding: "18px 24px",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            alt={userData?.name}
            src="/static/images/avatar/2.jpg"
            sx={{ margin: "0 auto" }}
          />
          <Typography>{userData?.name}</Typography>
          <Typography>{userData?.eMail}</Typography>
        </MenuItem>
        <Divider />
        <AccountsMenu />
        <Divider />
        <LanguageMenu closeMenu={handleCloseUserMenu} />
        <Divider sx={{ marginY: 1 }} />
        <LegalMenu closeMenu={handleCloseUserMenu} />
        <Divider />
        <ManageMenu />
        <Divider />
        <LogoutMenu handleLogOut={handleLogOut} />
        <Divider />
        <CopyrightMenu />
      </Menu>
    </Box>
  );
};

export default UserMenu;
