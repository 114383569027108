import { createContext } from "react";

const IsCollectContext = createContext(undefined);

export const IsCollectProvider = ({ children, isCollect }) => {
  return (
    <IsCollectContext.Provider value={isCollect}>
      {children}
    </IsCollectContext.Provider>
  );
};

export default IsCollectContext;
