import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Autocomplete, Box, TextField } from "@mui/material";
import { timeOptions } from "constants/constants";
import {
  addSemicolon,
  findClosestTime,
  findCurrentIndex,
} from "helpers/helpers";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ButtonWithTooltip from "../ButtonWithTooltip/ButtonWithTooltip";

const TimeInput = ({
  label = "time",
  time,
  id,
  setIndex,
  index,
  options,
  registerName,
  isDisabled,
}) => {
  const { control, setValue } = useFormContext();
  const inputRegisterName = `defaultDeliveryWindow.${registerName}`;

  const addTime = (field) => {
    setIndex((prev) => {
      const newIndex = prev >= timeOptions.length - 1 ? prev : prev + 1;
      field.onChange(timeOptions[newIndex].label24h);
      return newIndex;
    });
  };

  const subtractTime = (field) => {
    setIndex((prev) => {
      const newIndex = prev <= 0 ? prev : prev - 1;
      field.onChange(timeOptions[newIndex].label24h);
      return newIndex;
    });
  };

  const handleTextInput = (timeString) => {
    const closestTime = findClosestTime(timeString, options[0].label24h);
    setIndex(findCurrentIndex(closestTime));
    return closestTime;
  };

  useEffect(() => {
    setValue(inputRegisterName, timeOptions[index].label24h);
  }, [index, inputRegisterName, setValue]);

  return (
    <Box sx={{ display: "flex", gap: "20px" }}>
      <Controller
        name={inputRegisterName}
        control={control}
        defaultValue={time}
        render={({ field }) => (
          <Autocomplete
            id={`oi-delivery-form-${id}`}
            freeSolo
            options={options}
            inputValue={field.value}
            disabled={isDisabled}
            filterOptions={(option) => option}
            onInputChange={(_, value) => field.onChange(addSemicolon(value))}
            onBlur={(e) => field.onChange(handleTextInput(e.target.value))}
            onChange={(_, value) => {
              if (value) field.onChange(handleTextInput(value.label24h));
            }}
            sx={{ width: "100%" }}
            getOptionLabel={(option) => option.label24h}
            renderInput={(params) => (
              <TextField
                disabled={isDisabled}
                label={label}
                variant="filled"
                sx={{ width: "100%" }}
                InputProps={{
                  ...params.InputProps,
                  style: { padding: 0, alignItems: "stretch" },
                  startAdornment: (
                    <ButtonWithTooltip
                      disabled={isDisabled || index === 0}
                      onClick={() => subtractTime(field)}
                    >
                      <KeyboardArrowLeftIcon />
                    </ButtonWithTooltip>
                  ),
                  endAdornment: (
                    <ButtonWithTooltip
                      disabled={isDisabled || index === timeOptions.length - 1}
                      onClick={() => addTime(field)}
                    >
                      <KeyboardArrowRightIcon />
                    </ButtonWithTooltip>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 5,
                  style: {
                    textAlign: "center",
                    width: "100%",
                    padding: "25px 0 8px 0",
                  },
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  style: {
                    top: "8%",
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    fontSize: "12px",
                  },
                }}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

export default TimeInput;
