import IsCollectContext from "context/IsCollectProvider";
import { useContext } from "react";

const useIsCollect = () => {
  const isCollect = useContext(IsCollectContext);

  return isCollect;
};

export default useIsCollect;
