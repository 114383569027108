import { apiSlice } from "./apiSlice";
const LOGIN = "login";
const LOGOUT = "logout/";
const VERIFY = "verify";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN,
        method: "POST",
        body: credentials,
      }),
    }),
    loginByPassword: builder.mutation({
      query: (credentials) => ({
        url: `${VERIFY}/password`,
        method: "POST",
        body: credentials,
      }),
    }),
    loginByCode: builder.mutation({
      query: (loginData) => ({
        url: loginData.redirect,
        method: "POST",
        body: { code: loginData.code },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useLoginByPasswordMutation,
  useLoginByCodeMutation,
} = authApiSlice;
