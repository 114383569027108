import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ListIcon from "@mui/icons-material/List";
import { Alert, Button, ButtonBase, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

const OICopyPreviousOrder = ({ handleIsAlertVisible }) => {
  const { t } = useTranslation();

  return (
    <Alert
      icon={false}
      action={
        <ButtonGroup
          variant="text"
          sx={{
            ".MuiButtonGroup-grouped": {
              border: 0,
              mx: 1,
              lineHeight: "0",
            },
          }}
        >
          <Button size="small" startIcon={<DoneIcon />}>
            <span>{t("orderIntake.materialPersistency.yes")}</span>
          </Button>
          <Button size="small" startIcon={<ListIcon />}>
            {t("orderIntake.materialPersistency.viewLastOrder")}
          </Button>
          <ButtonBase onClick={handleIsAlertVisible}>
            <CloseIcon sx={{ fontSize: "20px" }} />
          </ButtonBase>
        </ButtonGroup>
      }
      severity="info"
      sx={{ mt: 4, flexWrap: "wrap" }}
      onClose={() => {}}
    >
      {t("orderIntake.materialPersistency.lastOrderDetails")}
    </Alert>
  );
};

export default OICopyPreviousOrder;
