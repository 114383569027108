import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonWithTooltip from "../../UI/ButtonWithTooltip/ButtonWithTooltip";

const IncrementInput = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const currentOption = useSelector((state) => state.orderIntake.currentOption);

  const { truckCapacity } = currentOption;
  const capacity = truckCapacity.capacity;
  const increment = truckCapacity.quantityChangeIncrement;
  const minCapacity = truckCapacity.minCapacity;
  const maxCapacity = truckCapacity.maxCapacity;

  const unitOfMeasure = t("capacityTons");

  const handleAmount = (amount) => {
    if (amount < minCapacity) return minCapacity;
    if (amount > maxCapacity) return maxCapacity;
    return amount;
  };

  const overMin = t("orderIntake.minTruckCapacity", {
    capacity: minCapacity,
    capacityUom: unitOfMeasure,
  });
  const overMax = t("orderIntake.maxTruckCapacity", {
    capacity: maxCapacity,
    capacityUom: unitOfMeasure,
  });

  return (
    <Controller
      name="truckCapacity.capacity"
      defaultValue={capacity}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          id="oi-delivery-form-quantity"
          label={`${t("orderIntake.quantity")} ${unitOfMeasure}`}
          variant="filled"
          value={field.value}
          onChange={(e) => field.onChange(Number(e.target.value))}
          onBlur={() => field.onChange(handleAmount(field.value))}
          sx={{ mb: 1, width: "100%" }}
          inputProps={{
            maxLength: 4,
            style: {
              textAlign: "center",
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          InputProps={{
            style: {
              padding: 0,
              alignItems: "stretch",
            },
            startAdornment: (
              <ButtonWithTooltip
                disabled={field.value === minCapacity}
                tooltipText={overMin}
                onClick={() => field.onChange(field.value - increment)}
              >
                <KeyboardArrowLeftIcon />
              </ButtonWithTooltip>
            ),
            endAdornment: (
              <ButtonWithTooltip
                disabled={field.value === maxCapacity}
                tooltipText={overMax}
                onClick={() => field.onChange(field.value + increment)}
              >
                <KeyboardArrowRightIcon />
              </ButtonWithTooltip>
            ),
          }}
          InputLabelProps={{
            style: {
              top: "8%",
              left: "50%",
              transform: "translate(-50%, 0)",
              fontSize: "12px",
              textTransform: "none",
            },
          }}
        />
      )}
    />
  );
};

export default IncrementInput;
