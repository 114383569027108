import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackMsg: "",
  isSnackOpen: false,
};

const snackMessagesSlice = createSlice({
  name: "snackMessages",
  initialState,
  reducers: {
    setSnackOpen: (state, action) => {
      state.isSnackOpen = action.payload;
    },
    setSnackMessage: (state, action) => {
      state.snackMsg = action.payload;
    },
  },
});

export const { actions } = snackMessagesSlice;
export default snackMessagesSlice.reducer;
