import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/ru";
import { useEffect, useMemo } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const HubDatePicker = () => {
  const { t, i18n } = useTranslation();
  const { control, watch, setValue, getValues, resetField } = useFormContext();

  const {
    dirtyFields: { customerReference: isDirtyCustomerReference },
  } = useFormState();

  const currentOption = useSelector((state) => state.orderIntake.currentOption);
  const deliveryDates = useSelector((state) => state.orderIntake.deliveryDates);

  const availableMoments = useMemo(
    () =>
      deliveryDates.length ? deliveryDates.map((item) => moment(item)) : [],
    [deliveryDates]
  );

  const enableDates = (date) =>
    !availableMoments?.some((item) => item.isSame(date));

  const date = getValues().date;

  useEffect(() => {
    const { edit } = getValues();

    let isResetDate = edit ? false : true;

    if (isResetDate) {
      resetField("date", { defaultValue: moment(deliveryDates[0]).format() });
    }
  }, [deliveryDates, getValues, resetField, setValue]);

  useEffect(() => {
    const materialToLookup = watch("materials.materialDescription");
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const isCutOffTime =
      +new Date(currentOption.businessDays.cutOffTime) > +new Date()
        ? "daysBeforeCutOffTime"
        : "daysAfterCutOffTime";

    const siteOnDate = currentOption.options.find(({ businessDays }) =>
      businessDays[isCutOffTime].includes(formattedDate)
    );

    const materialOnDate = siteOnDate?.materials.find(
      ({ materialDescription }) => materialDescription === materialToLookup
    );

    const wasPOEdited = getValues().wasPOEdited;
    isDirtyCustomerReference && setValue("wasPOEdited", true);

    if (!wasPOEdited && !isDirtyCustomerReference) {
      siteOnDate?.customerReference &&
        setValue("customerReference", siteOnDate?.customerReference);
    }
    materialOnDate && setValue("materials", materialOnDate);
  }, [
    currentOption.businessDays.cutOffTime,
    currentOption.options,
    isDirtyCustomerReference,
    date,
    getValues,
    setValue,
    watch,
  ]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.language}
    >
      <Controller
        id="oi-delivery-form-date"
        name="date"
        control={control}
        render={({ field }) => (
          <DatePicker
            value={moment(field.value)}
            onChange={(date) => {
              field.onChange(date.format());
            }}
            shouldDisableDate={enableDates}
            disablePast
            label={t("orderIntake.materialOrder.date")}
            sx={{ width: "100%" }}
            slotProps={{ textField: { required: true } }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default HubDatePicker;
