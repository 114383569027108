import { TextField, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const POInput = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Typography marginBottom={1}>
        {t("orderIntake.customerReference")}
      </Typography>
      <TextField
        fullWidth
        id="oi-delivery-form-PO"
        label={t("orderIntake.customerReference")}
        helperText={t("required")}
        variant="filled"
        error={!!errors.customerReference}
        FormHelperTextProps={{ sx: { ml: 0 } }}
        placeholder={t("orderIntake.customerReferencePlaceholder")}
        InputProps={{
          ...register("customerReference", {
            required: true,
          }),
        }}
        sx={{ mb: 1 }}
      />
    </>
  );
};

export default POInput;
