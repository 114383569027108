import { timeOptions } from "constants/constants";
import i18next from "i18next";
import moment from "moment";
const { t, languages, language } = i18next;

export const formatOnlyNumbers = (timeString) =>
  Number(timeString.replace(/\D/g, ""));

export const formatTime = (initialTime) => {
  if (!initialTime) return "00:00";
  const hours = Math.floor(initialTime / 3600);
  const minutes = Math.floor((initialTime % 3600) / 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const addSemicolon = (timeString) => {
  const onlyNumbers = timeString.replace(/\D/g, "");
  const matches = onlyNumbers.match(/^(\d{0,2})?(\d{0,2})/);
  const formatedInputTime =
    matches && [matches[1], matches[2] ? ":" : "", matches[2]].join("");

  return formatedInputTime;
};

export const getAvailableDate = (date) => {
  if (moment(date).isAfter()) return date;
  else return moment().add(1, "days").format("YYYY-MM-DD");
};

export const findClosestTime = (timeString, defaultValue = "00:00") => {
  if (timeString.length < 4 || !timeString) return defaultValue;
  const timeArray = timeString.split(":");

  if (!timeArray[0]) timeArray[0] = "00";
  if (!timeArray[1]) timeArray[1] = "00";

  const numHours = Number(timeArray[0]);
  const numMinutes = Number(timeArray[1]);

  if (numHours > 23) return defaultValue;

  if (numHours >= 23 && numMinutes > 44) {
    timeArray[1] = "59";
  } else if (numMinutes > 14) {
    timeArray[1] = "30";
  } else if (numMinutes > 44) {
    timeArray[1] = "00";
    timeArray[0] = numHours + 1;
  } else {
    timeArray[1] = "00";
  }

  const closestTime = timeOptions.find(({ label24h }) =>
    label24h.includes(`${timeArray[0]}:${timeArray[1]}`)
  );
  return closestTime?.label24h;
};

export const findCurrentIndex = (timeValue) => {
  const currentIndex = timeOptions.findIndex(
    (time) => time.label24h === findClosestTime(timeValue)
  );

  return currentIndex;
};

export const sortByMaterial = (acc, item) => {
  if (!acc || !item) return acc;

  const materialDescription = item.materials?.materialDescription;
  if (!materialDescription) return acc;

  const currentMaterial = acc[materialDescription] || { orders: [], total: 0 };

  return {
    ...acc,
    [materialDescription]: {
      orders: [...currentMaterial.orders, item],
      total: currentMaterial.total + (item?.truckCapacity?.capacity || 0),
    },
  };
};

export const localizeDate = (string) =>
  new Date(string).toLocaleDateString(languages, {
    month: "numeric",
    day: "2-digit",
    year: "numeric",
  });

export const localizeTime = (string) =>
  new Date(string).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

export const localizeCurrency = (amount, currency) =>
  new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency,
  }).format(amount);

export const downloadFileUsingType = async (request) => {
  if (!request?.href) return alert("No link to document!");
  try {
    const response = await fetch("/api/v1/" + request.href, {
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
    });
    if (!response.ok) return;

    const blob = await response.blob();
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: request.media })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", request.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};

export const downloadFileContentDisposition = async (href) => {
  const response = await fetch(href, {
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.blob();

  const elm = document.createElement("a");
  elm.href = URL.createObjectURL(data);
  elm.setAttribute(
    "download",
    response.headers
      .get("Content-Disposition")
      .split("filename=")[1]
      .replaceAll('"', "")
  );
  elm.click();
  elm.remove();
};

export const formattedDateRange = (...dates) => {
  const dateOne = dates[0] && new Date(dates[0]);
  const dateTwo = dates[1] && new Date(dates[1]);
  if (dates.length === 1) {
    return new Intl.DateTimeFormat(language).format(dateOne);
  }
  if (dates.length > 1 && dateOne.getDate() === dateTwo.getDate()) {
    return new Intl.DateTimeFormat(language).format(dateOne);
  }
  return `${new Intl.DateTimeFormat(language).format(
    dateOne
  )}-${new Intl.DateTimeFormat(language).format(dateTwo)}`;
};

export const calculatedPage = (offset, limit) => Math.floor(offset / limit);
export const dateComparator = (a, b) =>
  new Date(a.date).getDate() - new Date(b.date).getDate();
export const calculatedWidth = (amount, total) => `${(amount / total) * 100}%`;

export const getYYYYMMDDfromIso = (date) =>
  (date ? new Date(date) : new Date()).toISOString().split("T")[0];

export const convertPaymentStatusToQuery = (statuses) => {
  if (statuses.includes(t("filterMenu.allStatus")))
    return "open,closed,partially_paid";

  const statusMap = {
    "invoice.paymentStatusFilter.open": "open",
    "invoice.paymentStatusFilter.closed": "closed",
    "invoice.paymentStatusFilter.partiallyPaid": "partially_paid",
  };

  return statuses
    .map((status) => statusMap[status] || "")
    .filter(Boolean)
    .join(",");
};

export const formatInvoiceStatusText = (status) => {
  const invoiceStatuses = {
    closed: t("invoice.statuses.closed"),
    open: t("invoice.statuses.open"),
    partially_paid: t("invoice.statuses.partiallyPaid"),
  };

  return invoiceStatuses[status];
};

export const convertDeliveryTypeToText = (type) => {
  if (type === "deliver") return t("order.orderDetails.deliver");
  if (type === "collect") return t("order.orderDetails.collect");
};

export const convertOrderStatusToText = (status) => {
  const statusTooltips = {
    confirmed: t("order.orderStatusLabel.confirmed"),
    completed: t("order.orderStatusLabel.completed"),
    canceled: t("order.orderStatusLabel.cancelled"),
    active: t("order.orderStatusLabel.active"),
    unconfirmed: t("order.orderStatusLabel.pending"),
    notValidated: t("order.orderStatusLabel.pending"),
    created: t("order.orderStatusLabel.pending"),
    declined: t("order.orderStatusLabel.rejected"),
  };

  return statusTooltips[status];
};

export const groupInvoiceDeliveries = (invoiceData) => {
  const groupedDeliveries = Object.groupBy(
    invoiceData.lineItems,
    ({ materialDescription, contractNumber, shippingDate }) =>
      `${materialDescription}-${contractNumber}-${getYYYYMMDDfromIso(
        shippingDate
      )}`
  );

  const groupedDeliveriesValues = Object.values(groupedDeliveries);

  const groupedTableRows = groupedDeliveriesValues.map((delivery) => {
    if (delivery.length === 1)
      return {
        unitPrice: delivery[0].unitPrice,
        quantity: delivery[0].quantity,
        quantityUom: delivery[0].quantityUom,
        shippingDate: delivery[0].shippingDate,
        contractNumber: delivery[0].contractNumber,
        materialDescription: delivery[0].materialDescription,
        currency: invoiceData.invoiceCurrency,
      };

    return delivery.reduce((acc, item) => ({
      unitPrice: acc.unitPrice + item.unitPrice,
      quantity: acc.quantity + item.quantity,
      quantityUom: acc.quantityUom,
      shippingDate: acc.shippingDate,
      contractNumber: acc.contractNumber,
      materialDescription: acc.materialDescription,
      currency: invoiceData.invoiceCurrency,
    }));
  });

  return { groupedDeliveriesValues, groupedTableRows };
};

export const getCutOffDates = (site) => {
  if (!site?.businessDays) return undefined;
  const { cutOffTime, daysBeforeCutOffTime, daysAfterCutOffTime } =
    site.businessDays;
  return +new Date(cutOffTime) > +new Date()
    ? daysBeforeCutOffTime
    : daysAfterCutOffTime;
};
export const resetFormObject = (option) => {
  const {
    customerReference,
    date,
    defaultDeliveryWindow,
    driverInstructions,
    materials,
    truckCapacity,
  } = option;

  return {
    orderId: 0,
    isOrderSentError: false,
    isTimeOut: false,
    customerReference: customerReference || "",
    wasPOEdited: false,
    edit: false,
    date: moment(date).format(),
    defaultDeliveryWindow,
    driverInstructions,
    materials: materials[0],
    truckCapacity: { capacity: truckCapacity.capacity },
    haulerInfo: {
      driverName: "",
      driverPhoneNumber: "",
      truckLicensePlate: "",
      trailerLicensePlate: "",
    },
  };
};
