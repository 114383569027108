import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { useActions } from "hooks/useActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const HubSnackbar = () => {
  const { setSnackOpen, setSnackMessage } = useActions();

  const snackMsg = useSelector((state) => state.snackMessages.snackMsg);
  const isSnackOpen = useSelector((state) => state.snackMessages.isSnackOpen);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackOpen(false);
    setSnackMessage("");
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    if (snackMsg !== "") setSnackOpen(true);
  }, [snackMsg, setSnackOpen]);

  return (
    <div>
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={6 * 1000}
        onClose={handleClose}
        message={snackMsg}
        action={action}
      />
    </div>
  );
};

export default HubSnackbar;
