import { Box, Divider } from "@mui/material";
import CreditWidgetInvoicePage from "Components/CreditWidget/CreditWidgetInvoicePage";
import { PageTypeProvider } from "context/PageTypeProvider";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import ContentTable from "../Components/UI/PageComponents/ContentTable/ContentTable";
import ItemDetails from "../Components/UI/PageComponents/ItemDetails";
import LeftGrid from "../Components/UI/PageComponents/LeftGrid";
import MainContentGridContainer from "../Components/UI/PageComponents/MainContentGridContainer";
import PageCard from "../Components/UI/PageComponents/PageCard/PageCard";
import PageFilters from "../Components/UI/PageComponents/PageFilters";
import PageWrapper from "../Components/UI/PageComponents/PageWrapper";
import RightGrid from "../Components/UI/PageComponents/RightGrid";
import { PageHeading } from "../Components/UI/Typography/SharedTypography";

const Invoices = () => {
  const { t } = useTranslation();

  const isDesktop = useIsDesktop();
  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("Invoices");

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;
  return (
    <PageWrapper>
      <PageHeading>{t("invoice.title")}</PageHeading>
      <PageTypeProvider PageType="Invoices">
        <MainContentGridContainer>
          <LeftGrid>
            {isDesktop ? (
              <>
                <PageFilters />
                <ContentTable />
              </>
            ) : (
              <>
                <Box sx={{ padding: 1 }}>
                  <CreditWidgetInvoicePage />
                </Box>
                <Divider sx={{ marginTop: 1 }} />
                <PageCard />
              </>
            )}
          </LeftGrid>
          {isDesktop && (
            <RightGrid>
              <ItemDetails />
            </RightGrid>
          )}
        </MainContentGridContainer>
      </PageTypeProvider>
    </PageWrapper>
  );
};

export default Invoices;
