import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { setItemToLS } from "helpers/localstoragehelper";
import { useActions } from "hooks/useActions";
import { useVisualError } from "hooks/useError";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  useLoginByPasswordMutation,
  useLoginMutation,
} from "reduxStore/api/authApiSlice";

const StyledTextnput = styled((props) => (
  <TextField hiddenLabel variant="filled" required {...props} />
))(() => ({
  ".Mui-error": {
    border: "1px solid red",
    borderRadius: "4px",
  },
  ".MuiFilledInput-root": {
    paddingRight: 0,
  },
}));

const ByPassword = ({ username, handleUsername, setCodeLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isErrorVisual, setErrorVisual] = useVisualError();
  const { setSnackMessage } = useActions();

  const [loginByPassword, { isLoading: isLoadingWithPassword }] =
    useLoginByPasswordMutation();

  const [checkLogin, { isLoading: isCheckingLogin }] = useLoginMutation({
    fixedCacheKey: "shared-login-check",
  });

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handlePassword = ({ target: { value } }) => setPassword(value);

  const submitPassword = async (e) => {
    e.preventDefault();
    await loginByPassword({
      login: username,
      password,
    })
      .unwrap()
      .then((userData) => {
        setItemToLS("token", userData?.token);
        setItemToLS("userId", userData?.userId);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setSnackMessage(`Error ${error?.status}: ${error?.data?.error}`);
        setErrorVisual(true);
      });
  };

  const submitLoginForCode = async () => {
    await checkLogin({
      login: username,
    })
      .unwrap()
      .then(() => setCodeLogin())
      .catch((rejected) => console.error(rejected));
  };

  return (
    <>
      <Typography variant="h6" marginBottom={3}>
        {t("landingPage.login")}
      </Typography>
      <form style={{ display: "contents" }} onSubmit={submitPassword}>
        <StyledTextnput
          value={username}
          name="zakaz-login"
          onChange={handleUsername}
          error={isErrorVisual}
          placeholder="login"
          sx={{ marginBottom: 1 }}
        />
        <StyledTextnput
          value={password}
          name="zakaz-password"
          onChange={handlePassword}
          error={isErrorVisual}
          placeholder="password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          disabled={isLoadingWithPassword}
          type="submit"
        >
          {t("landingPage.loginButton")}
        </Button>
        <Button
          onClick={submitLoginForCode}
          disabled={isCheckingLogin}
          sx={{ marginY: 2 }}
        >
          {t("landingPage.getCode")}
        </Button>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            flexWrap: "wrap",
            gap: "12px",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography>{t("landingPage.requestAccessText")}</Typography>
            <Link>{t("landingPage.requestAccessLink")}</Link>
          </Box>
          <Box>
            <Typography>{t("landingPage.forgotPassword")}</Typography>
            <Link>{t("landingPage.resetPassword")}</Link>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ByPassword;
