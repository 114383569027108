import styled from "@emotion/styled";
import { Box, Button, Link, Modal, Typography } from "@mui/material";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const StyledTypographyBold = styled((props) => (
  <Typography variant="h6" {...props} />
))(() => ({
  fontWeight: 600,
}));

const StyledTypographyMB = styled((props) => <Typography {...props} />)(() => ({
  marginBottom: "8px",
}));

const CookieModal = () => {
  const { t } = useTranslation();
  const userData = useUser();
  const hrefBase = "https://legal.hconnect.digital/";
  const hrefLegal = hrefBase + "legal/dpp/";

  const [open, setOpen] = useState(true);
  const [openManage, setOpenManage] = useState(false);
  const handleClose = (_, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);
  };

  const handleManage = () => {
    setOpenManage(true);
  };

  useEffect(() => {
    if (userData?.isTermsApprovalRequired) setOpen(true);
  }, [userData?.isTermsApprovalRequired]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-cookies">
      <Box>
        <Box sx={{ px: 4, py: 2, backgroundColor: "white" }}>
          <StyledTypographyBold
            variant="h6"
            sx={{ textTransform: "uppercase" }}
          >
            🍪 {t("cookie.title")}
          </StyledTypographyBold>
          <Typography sx={{ mb: 3 }}>
            <Trans
              i18nKey="cookie.description"
              values={{ href: hrefLegal }}
              components={{
                href: <Link href={hrefLegal}>{hrefLegal}</Link>,
              }}
            />
          </Typography>

          {openManage && (
            <Box>
              <StyledTypographyBold>
                {t("cookie.policyInformation.whatAreCookiesTitle")}
              </StyledTypographyBold>
              <StyledTypographyMB>
                {t("cookie.policyInformation.whatAreCookies")}
              </StyledTypographyMB>
              <StyledTypographyBold>
                {t("cookie.policyInformation.legalBasisTitle")}
              </StyledTypographyBold>
              <StyledTypographyMB>
                <Trans
                  i18nKey="cookie.policyInformation.legalBasis"
                  values={{ href: hrefBase }}
                  components={{
                    href: <Link href={hrefBase}>{hrefBase}</Link>,
                  }}
                />
              </StyledTypographyMB>
              <StyledTypographyBold>
                {t("cookie.policyInformation.categoriesTitle")}
              </StyledTypographyBold>
              <StyledTypographyBold>
                {t("cookie.policyInformation.howCookiesWorkTitle")}
              </StyledTypographyBold>
              <StyledTypographyMB>
                {t("cookie.policyInformation.howCookiesWork")}
              </StyledTypographyMB>
              <StyledTypographyBold>
                {t("cookie.policyInformation.cookiesSettingsTitle")}
              </StyledTypographyBold>
              <StyledTypographyMB>
                <Trans
                  i18nKey="cookie.policyInformation.cookiesSettings"
                  values={{ href: hrefLegal }}
                  components={{
                    href: <Link href={hrefLegal}>{hrefLegal}</Link>,
                  }}
                />
              </StyledTypographyMB>
            </Box>
          )}

          <Box sx={{ display: "flex", columnGap: 1 }}>
            <Button variant="contained" size="small" onClick={handleAccept}>
              {t("cookie.acceptButton")}
            </Button>
            {!openManage && (
              <Button variant="outlined" size="small" onClick={handleManage}>
                {t("cookie.settingsButton")}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CookieModal;
