import { Alert, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useIsCollect from "hooks/useIsCollect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OITimeTabs from "../OITimeTabs";
import HubDatePicker from "./HubDatePicker";

const DateTimeTab = () => {
  const { t } = useTranslation();
  const isCollect = useIsCollect();
  const currentOption = useSelector((state) => state.orderIntake.currentOption);

  return (
    <Box position="relative">
      <Box>
        <Stack>
          <Box>
            <Box marginBottom={2}>
              <Typography variant="caption">
                {isCollect
                  ? t("orderIntake.materialOrder.location")
                  : t("orderIntake.materialOrder.shipTo")}
              </Typography>
              <Box>
                <Typography variant="subtitle">
                  {`${currentOption?.siteName}`}
                </Typography>
              </Box>
            </Box>
            <HubDatePicker />
            {false && (
              <Alert severity="warning" sx={{ mt: 3 }}>
                {t("orderIntake.supplyingDestinationDifferent")}
              </Alert>
            )}
            <OITimeTabs />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default DateTimeTab;
