import { Box, Button, styled, Typography } from "@mui/material";
import { useActions } from "hooks/useActions";
import useIsCollect from "hooks/useIsCollect";
import useUser from "hooks/useUser";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CaptionHeading,
  MainColorHeading,
} from "../UI/Typography/SharedTypography";
import OIPinCodeDetails from "./OIPinCodeDetails";

const AdditionalInfoBox = styled((props) => (
  <Box marginBottom={1} {...props} />
))(() => ({}));

const OIAdditionalInfo = () => {
  const { t } = useTranslation();
  const isCollect = useIsCollect();

  const navigate = useNavigate();
  const userData = useUser();

  const { setCurrentOption } = useActions();

  const currentOption = useSelector((state) => state.orderIntake.currentOption);
  const backToOrderList = () => {
    setCurrentOption(currentOption);
    navigate("/orders");
  };

  return (
    <>
      {isCollect && <OIPinCodeDetails />}
      <MainColorHeading marginBottom={2}>
        {t("orderIntake.confirmationScreen.additionalInfo")}
      </MainColorHeading>

      <AdditionalInfoBox>
        <CaptionHeading>
          {isCollect
            ? t("orderIntake.collectionAddress")
            : t("orderIntake.deliverTo")}
        </CaptionHeading>
        <Typography> {`${currentOption?.siteName}`}</Typography>
        <Typography>{`${currentOption?.street}`}</Typography>
        <Typography>{`${currentOption?.city} ${currentOption?.postalCode}`}</Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>
          {t("orderIntake.confirmationScreen.contactPerson")}
        </CaptionHeading>
        <Typography>{userData?.name}</Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>{t("orderIntake.mainPhone")}</CaptionHeading>
        <Typography>
          {currentOption?.contact?.mainPhone === "UNDEFINED"
            ? "n\\a"
            : currentOption?.contact?.mainPhone}
        </Typography>
      </AdditionalInfoBox>

      <AdditionalInfoBox>
        <CaptionHeading>
          {t("orderIntake.confirmationScreen.cancellation")}
        </CaptionHeading>
        <Typography>
          {t("orderIntake.changesOrCancellationsDisclaimer")}
        </Typography>
      </AdditionalInfoBox>

      <Box marginTop={4}>
        <Button variant="contained" fullWidth onClick={backToOrderList}>
          {t("orderIntake.confirmationScreen.backToOrdersList")}
        </Button>
      </Box>
    </>
  );
};

export default OIAdditionalInfo;
