import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import EventNoteIcon from "@mui/icons-material/EventNote";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Button, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useActions } from "hooks/useActions";
import useIsCollect from "hooks/useIsCollect";
import { useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  a11yProps,
  CustomTabPanel,
} from "../UI/GenericTabImports/GenericTabImports";
import StickyButtonBox from "../UI/StickyButtonBox/StickyButtonBox";
import CloseButton from "./OIForm/CloseButton";
import DateTimeTab from "./OIForm/DateTimeTab";
import DetailsTab from "./OIForm/DetailsTab";
import HaulerTab from "./OIForm/HaulerTab";
import OISummary from "./OISummary";

const StyledTab = styled((props) => <Tab iconPosition="start" {...props} />)(
  () => ({
    textTransform: "none",
    minHeight: "auto",
  })
);

const checkIsFormDirty = (...formFields) => {
  const isformDirty = formFields.some((field) => field === true);
  return {
    "&.MuiTab-root": {
      color: isformDirty && "rgb(238, 139, 0)",
    },
  };
};

const OITabsForm = () => {
  const { t } = useTranslation();
  const { dirtyFields } = useFormState();
  const isCollect = useIsCollect();

  const { handleSubmit, getValues, formState } = useFormContext();
  const { orderId } = getValues();

  const { removeTableRow, setIsTabsFormOpen, setSummary, setTableRow } =
    useActions();

  const [tabNum, setTabNum] = useState(0);
  const handleChange = (_, newValue) => setTabNum(newValue);
  const backButtonFunc = () => setTabNum(0);

  const currentOption = useSelector((state) => state.orderIntake.currentOption);
  const isSummaryOpen = useSelector((state) => state.orderIntake.isSummaryOpen);
  const isTabsFormOpen = useSelector(
    (state) => state.orderIntake.isTabsFormOpen
  );
  const currentOrderId = useSelector(
    (state) => state.orderIntake.currentOrderId
  );

  const handleClose = () => {
    setSummary();
    setIsTabsFormOpen(false);
  };

  const handleRemoveTableRow = (id) => removeTableRow(id);

  const submitHookForms = (data) => {
    const { customerReference } = dirtyFields;

    setTableRow({
      ...data,
      orderId: data?.orderId,
      wasPOEdited: customerReference,
    });
    setSummary();
  };

  if (!currentOption) return null;

  return isTabsFormOpen && !isSummaryOpen ? (
    <Box>
      <Box
        sx={{
          borderColor: "transparent",
          position: "relative",
        }}
      >
        <CloseButton onClick={handleClose} />
        <Tabs
          value={tabNum}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="delivery-form-tabs"
        >
          <StyledTab
            icon={<EventNoteIcon />}
            label={t("orderIntake.materialOrder.details")}
            {...a11yProps(0)}
            sx={() => {
              const {
                materials,
                truckCapacity,
                customerReference,
                driverInstructions,
              } = dirtyFields;

              return checkIsFormDirty(
                materials?.materialDescription,
                truckCapacity?.capacity,
                customerReference,
                driverInstructions
              );
            }}
          />
          <StyledTab
            icon={<DateRangeIcon />}
            label={t("orderIntake.materialOrder.dateTime")}
            {...a11yProps(1)}
            sx={() => {
              const { date, defaultDeliveryWindow } = dirtyFields;

              return checkIsFormDirty(
                date,
                defaultDeliveryWindow?.defaultEarliestLoadTime,
                defaultDeliveryWindow?.defaultLatestLoadTime
              );
            }}
          />
          {isCollect && (
            <StyledTab
              icon={<LocalShippingIcon />}
              label={t("orderIntake.haulerInfo.haulerInfo")}
              {...a11yProps(2)}
              sx={() => {
                const { haulerInfo } = dirtyFields;

                return checkIsFormDirty(
                  haulerInfo?.driverName,
                  haulerInfo?.driverPhoneNumber,
                  haulerInfo?.truckLicensePlate,
                  haulerInfo?.trailerLicensePlate
                );
              }}
            />
          )}
        </Tabs>
      </Box>

      <CustomTabPanel value={tabNum} index={0}>
        <DetailsTab />
      </CustomTabPanel>

      <CustomTabPanel value={tabNum} index={1}>
        <DateTimeTab />
      </CustomTabPanel>

      {isCollect && (
        <CustomTabPanel value={tabNum} index={2}>
          <HaulerTab />
        </CustomTabPanel>
      )}

      <StickyButtonBox>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
            marginBottom: 1,
          }}
        >
          {tabNum === 1 && (
            <Button
              onClick={backButtonFunc}
              variant="outlined"
              size="small"
              startIcon={<KeyboardBackspaceIcon />}
              sx={{ boxSizing: "content-box" }}
            >
              {t("orderIntake.materialOrder.back")}
            </Button>
          )}
          <Button
            disabled={!formState.isValid}
            onClick={() => {
              handleSubmit(submitHookForms)();
              setIsTabsFormOpen(false);
            }}
            variant="contained"
            fullWidth
            startIcon={<EastIcon />}
          >
            {t("orderIntake.materialOrder.apply")}
          </Button>
        </Box>
        {!!currentOrderId && (
          <Button
            variant="outlined"
            fullWidth
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => handleRemoveTableRow(orderId)}
          >
            {t("orderIntake.materialOrder.delete")}
          </Button>
        )}
      </StickyButtonBox>
    </Box>
  ) : (
    <OISummary />
  );
};

export default OITabsForm;
