import EastIcon from "@mui/icons-material/East";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OIOrderCard = ({ orderData }) => {
  const { t } = useTranslation();
  const { reset } = useFormContext();

  const tableRows = useSelector((state) => state.orderIntake.tableRows);
  const { removeTableRow, setIsTabsFormOpen, setSummary, setCurrentOrderId } =
    useActions();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "OI-OrderCards-popover" : undefined;

  const handleRowClick = (order) => {
    const clickedRow = tableRows.find(
      (tableRow) => tableRow.orderId === order.orderId
    );
    setCurrentOrderId(clickedRow.orderId);
    reset({ ...order });
    setIsTabsFormOpen(true);
  };

  const handleRemoveTableRow = (e, id) => {
    e.stopPropagation();
    removeTableRow(id);
    setSummary();
  };

  return (
    <Card variant="outlined" sx={{ my: 1 }}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            rowGap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="body2">
                  {t("orderIntake.materialOrder.date")}
                </Typography>
                <Box>
                  <Box>
                    <Typography variant="h6">
                      <FormatDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        timeStart={orderData?.date}
                      />
                      <Typography>{`${orderData.defaultDeliveryWindow.defaultEarliestLoadTime} - ${orderData.defaultDeliveryWindow.defaultLatestLoadTime}`}</Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <ButtonBase aria-describedby={id} onClick={handleClick}>
              <MoreHorizIcon />
            </ButtonBase>
          </Box>

          <Box>
            <Typography variant="body2">
              {t("orderIntake.materialOrder.type")}
            </Typography>
            <Box>
              <Box>
                <Typography variant="h6" sx={{ m: 0 }}>
                  {`${
                    orderData.materials[0]?.materialDescription ||
                    orderData.materials?.materialDescription
                  }`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2">
              {t("orderIntake.materialOrder.load")}
            </Typography>
            <Box>
              <Typography variant="h6">
                {orderData.truckCapacity.capacity}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body2">
              {t("orderIntake.driverInstructionsTooltip")}
            </Typography>
            <Typography variant="h6">
              {orderData?.driverInstructions || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {t("orderIntake.materialOrder.po")}
            </Typography>
            <Typography variant="h6">{orderData.customerReference}</Typography>
          </Box>
          <Divider />
          <Box>
            <Button
              startIcon={<EastIcon />}
              fullWidth
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={() => handleRowClick(orderData)}
            >
              {t("orderIntake.materialOrder.viewDetails")}
            </Button>
          </Box>
        </Box>
      </CardContent>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onBlur={handleClose}
      >
        <MenuItem>
          <ButtonBase onClick={() => handleRowClick(orderData)}>
            {t("orderIntake.materialOrder.edit")}
          </ButtonBase>
        </MenuItem>
        <MenuItem>
          <ButtonBase
            onClick={(e) => handleRemoveTableRow(e, orderData.orderId)}
          >
            {t("orderIntake.materialOrder.delete")}
          </ButtonBase>
        </MenuItem>
      </Menu>
    </Card>
  );
};

const OIOrderCards = () => {
  const tableRows = useSelector((state) => state.orderIntake.tableRows);

  return tableRows.map((row) => (
    <OIOrderCard key={row.orderId} orderData={row} />
  ));
};

export default OIOrderCards;
