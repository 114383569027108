import InfoIcon from "@mui/icons-material/Info";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { PageSubHeading } from "Components/UI/Typography/SharedTypography";
import useIsCollect from "hooks/useIsCollect";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IncrementInput from "./IncrementInput";
import MaterialDropdown from "./MaterialDropdown";
import OISpecialInstructionsInput from "./OISpecialInstructionsInput";
import POInput from "./POInput";

const DetailsTab = () => {
  const { t } = useTranslation();
  const isCollect = useIsCollect();
  const { getValues } = useFormContext();
  const { isTimeOut } = getValues();
  const currentOption = useSelector((state) => state.orderIntake.currentOption);

  return (
    <Box position="relative">
      <Box>
        <Stack>
          <Box>
            <Box marginBottom={1}>
              <Typography variant="caption">
                {isCollect
                  ? t("orderIntake.collectionAddress")
                  : t("orderIntake.deliverTo")}
              </Typography>
              <Box>
                <Typography variant="subtitle">
                  {`${currentOption?.siteName}`}
                </Typography>
                <br />
                <Typography variant="subtitle">
                  {`${currentOption?.street}, ${currentOption?.city}`}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: isTimeOut ? "block" : "none",
                marginBottom: 2,
                border: "1px solid red",
                background: (theme) =>
                  `${theme.palette.inputBackgroundColor.main}`,
                borderRadius: "4px",
                padding: 2,
              }}
            >
              <PageSubHeading
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InfoIcon sx={{ marginRight: 2 }} />
                The time for this date's delivery has expired.
                <br />
                Please select next available date.
              </PageSubHeading>
            </Box>

            <Stack sx={{ display: isTimeOut ? "none" : "block" }}>
              <MaterialDropdown />
              <IncrementInput />
            </Stack>
            <POInput />
            <OISpecialInstructionsInput />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default DetailsTab;
