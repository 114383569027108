import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DriverPhoneInput = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const validatePhone = (value) =>
    value === "" ? true : matchIsValidTel(value);

  return (
    <Controller
      name={"haulerInfo.driverPhoneNumber"}
      control={control}
      defaultValue={""}
      rules={{ validate: validatePhone }}
      render={({ field, fieldState }) => (
        <MuiTelInput
          fullWidth
          disableFormatting
          value={field.value}
          onChange={(e) => field.onChange(e)}
          error={!!fieldState.error}
          id="hauler-phone"
          label={t("orderIntake.haulerInfo.driverPhoneNumber")}
          variant="filled"
          defaultCountry="RU"
          preferredCountries={["RU"]}
          onlyCountries={["RU", "BY", "KZ"]}
          sx={{ mb: 2 }}
        />
      )}
    />
  );
};

export default DriverPhoneInput;
