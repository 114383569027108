import { actions } from "../slices/orderIntake";
import { apiSlice } from "./apiSlice";

const GET_OPTIONS = "order-requests/options/";
const POST_DELIVERIES = "order-request/";

const mergeSiteData = (existing, site, array) => ({
  ...existing,
  ...site,
  customerReference: array[0].customerReference,
  materials: existing?.materials
    ? [...existing.materials, ...site.materials]
    : [...site.materials],
  businessDays: {
    cutOffTime: site.businessDays.cutOffTime,
    daysAfterCutOffTime: existing?.businessDays?.daysAfterCutOffTime
      ? [
          ...existing.businessDays.daysAfterCutOffTime,
          ...site.businessDays.daysAfterCutOffTime,
        ]
      : [...site.businessDays.daysAfterCutOffTime],
    daysBeforeCutOffTime: existing?.businessDays?.daysBeforeCutOffTime
      ? [
          ...existing.businessDays.daysBeforeCutOffTime,
          ...site.businessDays.daysBeforeCutOffTime,
        ]
      : [...site.businessDays.daysBeforeCutOffTime],
  },
  options: existing?.options ? [...existing.options, site] : [site],
});

const filterUniqueMaterials = (materials) => {
  const uniqueMaterials = [];
  return materials.filter((material) => {
    if (uniqueMaterials.includes(material.materialEnteredNumber)) {
      return false;
    } else {
      uniqueMaterials.push(material.materialEnteredNumber);
      return true;
    }
  });
};

export const deliveryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryOptions: builder.query({
      query: (shippingType) => `${GET_OPTIONS}${shippingType}/`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: deliveryOptions } = await queryFulfilled;
          console.log("deliveryOptions", deliveryOptions);

          if (deliveryOptions.length === 0)
            return dispatch(actions.setCurrentOption(undefined));

          const groupedSites = Object.groupBy(
            deliveryOptions,
            ({ siteName, siteNumber, street, city }) =>
              `${siteName}-${siteNumber}-${street}-${city}`
          );

          console.log(groupedSites);

          const merged = {};
          for (const property in groupedSites) {
            groupedSites[property].forEach((site, _, array) => {
              merged[property] = mergeSiteData(merged[property], site, array);
            });
            merged[property].materials = filterUniqueMaterials(
              merged[property].materials
            );
          }

          const sitesResult = [];
          for (const property in merged) {
            sitesResult.push(merged[property]);
          }

          dispatch(actions.setDeliveryOptions(sitesResult));
          dispatch(actions.setCurrentOption(sitesResult[0]));
        } catch (err) {
          console.log(err);
        }
      },
      transformResponse: (response) => response.results,
    }),
    sendOrders: builder.mutation({
      query: (payload) => ({
        url: POST_DELIVERIES,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const { useGetDeliveryOptionsQuery } = deliveryApiSlice;
export const { useSendOrdersMutation } = deliveryApiSlice;
