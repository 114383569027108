import { Box, CardContent, Grid, Paper } from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import { useTranslation } from "react-i18next";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";
import FullScreenDeliveriesStatus from "./FullScreenDeliveriesStatus";
import FullScreenGridItem from "./FullScreenGridItem";

const FullscreenDeliveriesMobile = ({ deliveries }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
          background: (theme) => theme.palette.inputBackgroundColor.main,
        }}
      >
        <CardContent sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <FullScreenGridItem>
              <OrderCardHeading>
                {t("order.expand.deliveryNumber")}
              </OrderCardHeading>
              <PdfLink>{deliveries.deliveryNumber}</PdfLink>
            </FullScreenGridItem>
            <FullScreenGridItem sx={{ display: "flex", columnGap: "5px" }}>
              <FullScreenDeliveriesStatus data={deliveries} />
            </FullScreenGridItem>
            <FullScreenGridItem xs={12}>
              <OrderCardHeading> {t("order.expand.leftAt")}</OrderCardHeading>
              <OrderCardText>
                <FormatDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  timeStart={deliveries.shippingDate}
                />
              </OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>{t("order.expand.qtyLoaded")}</OrderCardHeading>
              <OrderCardText>{`${deliveries.loadQuantity} ${deliveries.loadQuantityUom}`}</OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>
                {t("order.expand.modeoftransport.truck")}
              </OrderCardHeading>
              <OrderCardText>{deliveries.truckLicensePlate}</OrderCardText>
            </FullScreenGridItem>
            <FullScreenGridItem>
              <OrderCardHeading>{t("order.expand.plantName")}</OrderCardHeading>
              <OrderCardText>{deliveries.plantName}</OrderCardText>
            </FullScreenGridItem>
          </Grid>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default FullscreenDeliveriesMobile;
