import CloseIcon from "@mui/icons-material/Close";
import { ButtonBase } from "@mui/material";

const CloseButton = ({ onClick }) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "-4px",
        right: "-4px",
        zIndex: 2,
      }}
    >
      <CloseIcon color="primary" sx={{ fontSize: "20px" }} />
    </ButtonBase>
  );
};

export default CloseButton;
