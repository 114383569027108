import { Button, Tooltip } from "@mui/material";

export const ButtonWithTooltip = ({
  tooltipText = "",
  disabled = false,
  onClick,
  children,
}) => {
  return (
    <Tooltip title={disabled && tooltipText}>
      <span>
        <Button
          disabled={disabled}
          onClick={onClick}
          sx={{
            color: (theme) => theme.palette.text.primary,
            height: "100%",
            "&:hover": {
              background: (theme) => theme.palette.inputBackgroundColor.main,
            },
          }}
        >
          {children}
        </Button>
      </span>
    </Tooltip>
  );
};

export default ButtonWithTooltip;
