import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Paper, Stack, TextField } from "@mui/material";
import { useActions } from "hooks/useActions";
import useIsCollect from "hooks/useIsCollect";
import { useIsDesktop } from "hooks/useIsDesktop";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PhoneInput from "../UI/PhoneInput/PhoneInput";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import OICollectionAddress from "./OICollectionAddress";
import OICopyPreviousOrder from "./OICopyPreviousOrder";
import DestinationDropdown from "./OIForm/DestinationDropdown";
import OIOrderCards from "./OIOrderCards";
import DeliverIntakeTable from "./OITable";

const OIOverview = () => {
  const { t } = useTranslation();
  const isCollect = useIsCollect();
  const { addAnotherDelivery } = useActions();
  const userData = useUser();
  const isDesktop = useIsDesktop();
  const { setValue, reset, resetField, getValues } = useFormContext();
  const tableRows = useSelector((state) => state.orderIntake.tableRows);

  const [isCopyPreviousOrderVisible, setIsCopyPreviousOrderVisible] =
    useState(true);

  const handleIsAlertVisible = () =>
    setIsCopyPreviousOrderVisible((prev) => !prev);
  const handleAddAnotherDelivery = () => {
    setValue("edit", true);
    reset({ ...tableRows.at(-1), edit: true }, { keepValues: true });
    addAnotherDelivery();
  };

  return (
    <>
      <PageSubHeading marginBottom={2}>
        {isCollect
          ? t("orderIntake.collectionAddress")
          : t("orderIntake.deliveryAddress")}
      </PageSubHeading>
      {isCollect ? <OICollectionAddress /> : <DestinationDropdown />}
      {isCopyPreviousOrderVisible && (
        <OICopyPreviousOrder handleIsAlertVisible={handleIsAlertVisible} />
      )}
      <Stack marginTop={4}>
        <PageSubHeading marginBottom={2}>
          {t("orderIntake.cementTypeDelivery")}
        </PageSubHeading>
        {isDesktop ? <DeliverIntakeTable /> : <OIOrderCards />}
        <Paper
          onClick={handleAddAnotherDelivery}
          sx={{
            p: 1,
            backgroundColor: "rgba(1, 125, 209, .02)",
            "&:hover": { boxShadow: 4 },
            cursor: "pointer",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            size="small"
            sx={{ textTransform: "capitalize" }}
          >
            {t("orderIntake.addDelivery")}
          </Button>
        </Paper>
      </Stack>
      <Box marginTop={2}>
        <PageSubHeading marginBottom={1}>
          {t("orderIntake.contactDetails")}
        </PageSubHeading>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            columnGap: 2,
          }}
        >
          <TextField
            id="contact-fullname"
            label={t("orderIntake.fullName")}
            defaultValue={userData?.name}
            helperText={t("required")}
            variant="filled"
            FormHelperTextProps={{
              sx: { ml: 0 },
            }}
            sx={{ flexBasis: "45%" }}
          />
          <PhoneInput label={t("orderIntake.mainPhone")} visible />
          {!isCollect && (
            <PhoneInput label={t("orderIntake.backupPhone")} isHideable />
          )}
        </Box>
      </Box>
    </>
  );
};

export default OIOverview;
